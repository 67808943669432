import React, { useMemo } from 'react';
import { IconDocument } from '@veneer/core';
import SolutionCardTemplate from './SolutionCardTemplate';

const SolutionCards = () => {
  const hasInstantInk = true;
  const hasPaas = true;
  const hasSustainability = true;

  const CardList = useMemo(() => [
    hasInstantInk
    && {
      headerText: '1 printer eligible',
      title: 'HP Instant Ink',
      description: 'Enroll and save up to 50% on ink and toner and never run out again.',
      icon: <IconDocument size={40} />,
      footerHyperlink: 'https://hpsmart.com',
      footerText: 'Learn more',
      dataTestId: 'instant-ink-card',
    },
    hasPaas
    && {
      headerText: 'Printer, Ink and 24/7 Support',
      title: 'HP All-in Plan',
      description: `Enjoy hassle-free printing with the HP All-In Plan.
      Get all your printing essentials for one low monthly payment.`,
      icon: <IconDocument size={40} />,
      footerHyperlink: 'https://hpsmart.com',
      footerText: 'Learn more',
      dataTestId: 'hp-all-in-plan-card',
    },
    hasSustainability
    && {
      headerText: 'Find more ways to be sustainable',
      title: 'Sustainability',
      description: 'Recycle used ink cartridges for free with HP Planet partners and learn how you can'
                 + 'print more sustainably.',
      icon: <IconDocument size={40} />,
      footerHyperlink: 'https://hpsmart.com',
      footerText: 'Learn more',
      dataTestId: 'sustainability-card',
    }].filter(Boolean), [hasInstantInk, hasPaas, hasSustainability]);

  if (!CardList.length) {
    return null;
  }

  return CardList.map(cardInfo => (
    <SolutionCardTemplate
      key={cardInfo.dataTestId} {...cardInfo}
      data-testid={cardInfo.dataTestId}
    />
  ));
};

export default SolutionCards;
