import styled from 'styled-components';
import primitives from '@veneer/primitives';
import { mixins } from '@jarvis/react-portal-addons';
import { Card } from '@veneer/core';

export const NotificationsWidgetContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 64px;
  background-color: #E6F0F5;
  padding: 20px 40px 20px 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mixins.lg(`
    width: 420px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 24px;
    border-radius: 8px;
  `)}

  ${mixins.xl(`
    width: 493px;
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
  `)}
`;

export const NotificationsLeftContent = styled.div`
  display: flex;
  align-items: center;

  ${mixins.lg(`
    margin-bottom: 12px;
  `)}

  ${mixins.xl(`
    margin-bottom: 0;
  `)}
`;

export const NotificationsCounter = styled.div`
  width: 32px;
  height: 24px;
  min-width: 32px;
  min-height: 24px;
  border-radius: 30px;
  background-color: #CC2C00;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${primitives.color.white};
`;

export const NotificationsMessage = styled.div`
  margin: 0px 12px;
  color: ${primitives.color.gray12};
`;

export const NotificationsShowMoreButton = styled.div`
  color: ${primitives.color.hpBlue7};
  cursor: pointer;

  ${mixins.lg(`
    margin: 8px 0 0 12px;
  `)}

  ${mixins.xl(`
    margin: 0 0;
  `)}
`;

export const NotificationsWidgetExpandedContainer = styled(Card)`
  && {
    background-color: #f0f0f0;
    width: 100%;
    height: fit-content;
    margin-bottom: 24px;
    border-radius: 0;
    ${mixins.lg(`
      margin-bottom: 0;
      border-radius: 16px;
    `)}
  }
`;
