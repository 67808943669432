import styled from 'styled-components';
import { mixins } from '@jarvis/react-portal-addons';
import primitives from '@veneer/primitives';
import { Button as VeneerButton } from '@veneer/core';

export const Container = styled.div`
  background-color: ${primitives.color.hpBlue7};
  box-sizing: border-box;
  height: fit-content;
  min-height: ${props => (props.accountHasDevice ? 0 : '392px')};
  padding: 0 0 28px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 992px) {
    min-height: ${props => (props.accountHasDevice ? 0 : '536px')};
  }

  ${mixins.lg(`
    border-radius: 16px;
    margin: 24px 32px 0 32px;
    padding: 32px 0 40px 0;
  `)}

  @media (min-width: 1920px) {
    margin: 28px 36px 0 36px;
  }
`;

export const Title = styled.h3`
  margin: 20px 32px 20px 32px;
  color: ${primitives.color.white};
  font-weight: 700 !important;

  ${mixins.lg(`
    margin: 0 20px 0 0;
  `)}
`;

export const PrinterCarouselContainer = styled.div`
  ${mixins.lg(`
    margin: 40px 0 0 0;
  `)}
`;

export const TitleAndNotificationsWrapper = styled.div`
  flex-direction: ${props => (props.accountHasDevice ? 'row-reverse' : 'row')};

  ${mixins.lg(`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px 0 40px;
  `)}
`;

export const MessageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > p {
    color: ${primitives.color.white};
    text-align: center;
    padding: 0 32px;
  }
`;

export const Button = styled(VeneerButton)`
  && {
    background-color: ${primitives.color.white};
    color: ${primitives.color.gray12};
    margin-top: 40px;

    &:hover {
      background-color: ${primitives.color.white};
      color: ${primitives.color.gray12};
    }
  }
`;
