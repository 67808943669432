/* eslint-disable i18next/no-literal-string */
import React from 'react';
import PropTypes from 'prop-types';
import { MFELoader } from '@jarvis/react-portal-addons';
import { getModuleInitializedEvent, publishEvent } from '../../../utils/analytics';
import {
  NotificationsCounter, NotificationsLeftContent, NotificationsMessage,
  NotificationsShowMoreButton, NotificationsWidgetContainer,
  NotificationsWidgetExpandedContainer,
} from './styles';

const MFE_REFS = {
  NOTIFICATION: '@jarvis/react-smb-notifications',
};

const NotificationsWidget = ({
  isExpanded, setIsExpanded, stack, properties,
}) => {
  if (isExpanded) {
    return (
      <NotificationsWidgetExpandedContainer
        data-testid="notifications-widget-expanded"
        onClick={() => setIsExpanded(false)} background="filled"
        border="outlined"
        content={(
          <MFELoader
            analytics={{
              enable: true,
              events: {
                moduleDisplayed: {
                  activity: 'Home-v01',
                },
              },
            }}
            card
            component={MFE_REFS.NOTIFICATION}
            data-testid="smart-notification-widget-mfe"
            header={false}
            onLoad={() => {
              publishEvent(getModuleInitializedEvent('ReactSmbNotifications'));
            }}
            properties={properties}
            stack={stack}
            type="ConsolidatedSmartNotification"
          />
        )}
      />
    );
  }

  return (
    <NotificationsWidgetContainer data-testid="notifications-widget">
      <NotificationsLeftContent>
        <NotificationsCounter className="label-small">
          6
        </NotificationsCounter>
        <NotificationsMessage className="label-regular">
          Smart Notifications need your attention.
        </NotificationsMessage>
      </NotificationsLeftContent>
      <NotificationsShowMoreButton className="label-regular" onClick={() => setIsExpanded(true)}>
        Show More
      </NotificationsShowMoreButton>
    </NotificationsWidgetContainer>
  );
};

NotificationsWidget.defaultProps = {
  stack: 0,
  properties: {},
};

NotificationsWidget.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  setIsExpanded: PropTypes.func.isRequired,
  stack: PropTypes.number,
  properties: PropTypes.objectOf(PropTypes.any),
};

export default NotificationsWidget;
