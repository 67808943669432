import styled from 'styled-components';
import { Card } from '@veneer/core';
import primitives from '@veneer/primitives';
import { mixins } from '@jarvis/react-portal-addons';

export const CardBody = styled(Card)`
  && {
    grid-column: span 4;
    border-radius: 10px;
    border: 0px;
    height: 244px;
    
    ${mixins.xs(`
      grid-column: span 12;
      max-width: 576px;
      height: 184px;
    `)}
    
    ${mixins.sm(`
      grid-column: span 6;
    `)}

    ${mixins.md(` 
      grid-column: span 6;
    `)}
        
    ${mixins.xl(` 
      grid-column: span 4;
    `)}

    ${mixins.xx(` 
      height: 220px;
    `)}
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  height: 100%;

  ${mixins.xs(`
    width: 100%;
  `)}
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-self: flex-start;
  border-radius: 10px 10px 0 0;
  background: ${primitives.color.hpBlue0};
  height: 32px;

  ${mixins.xs(`
    height: 40px;
  `)};

  ${mixins.xx(`
    height: 36px;
  `)}
`;

export const HeaderText = styled.div`
  color: #404040;
  margin: auto;
  
  ${mixins.xs(`
    margin: 8px auto 8px auto;
  `)}

  ${mixins.xx(`
    margin: 8px auto 8px auto;
  `)}
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 0px 21px 28px 21px

`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${mixins.sm(`
    margin-top: -5px;
  `)}

  ${mixins.xx(`
    margin-top: 10px;
  `)}
`;

export const IconContainer = styled.div`
  size: fit-content;
  
  ${mixins.xs(`
    margin-top: 10px;
  `)}
`;

export const Title = styled.p`
  padding-bottom: 11px;
`;

export const Description = styled.div`
  height: 80px;

  ${mixins.xs(`
    height: 40px;
    margin-top: -10px;
  `)}

  ${mixins.sm(`
    margin-bottom: 0px;
  `)}
  
  ${mixins.lg(`
    width: 80%;
    margin-bottom: 5px;
  `)}
`;

export const Footer = styled.div`
  margin-top: 10px;

  ${mixins.xs(`
    margin-top: 20px;
  `)}
`;
