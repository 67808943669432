import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { MFELoader } from '@jarvis/react-portal-addons';
import {
  PrintersItemClicked,
  getModuleDisplayedEvent,
  getModuleInitializedEvent,
  publishEvent,
} from '../../../utils/analytics';

const MFE_REFS = {
  PRINTERS: '@jarvis/react-smb-printers',
};

const PrinterCarousel = ({ stack, devices }) => (
  <MFELoader
    analyticsEvents={{
      moduleDisplayed: getModuleDisplayedEvent('/ReactSmbPrinters/', 'Printers'),
      itemClicked: PrintersItemClicked,
    }}
    component={MFE_REFS.PRINTERS}
    onLoad={() => {
      publishEvent(getModuleInitializedEvent('ReactSmbPrinters'));
    }}
    devices={devices}
    stack={stack}
    type="SMBPrinterCarousel"
  />
);

PrinterCarousel.defaultProps = {
  stack: 1,
};

PrinterCarousel.propTypes = {
  stack: PropTypes.number,
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default memo(PrinterCarousel);
