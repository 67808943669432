import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useI18n, useRootContext, userOSHelper } from '@jarvis/react-portal-addons';
import NotificationsWidget from '../NotificationsWidget';
import PrinterCarousel from '../PrinterCarousel';
import useDeviceCacheApiCall from '../../../hooks/useDeviceCacheApiCall';
import { APP_URLS } from '../../../utils/constants';
import useUsersApiCall from '../../../hooks/useUsersApiCall';
import {
  Button, Container, MessageContainer, PrinterCarouselContainer, Title, TitleAndNotificationsWrapper,
} from './styles';

const Banner = ({ 'data-testid': dataTestId }) => {
  const [noticationsIsExpanded, setNotificationsIsExpanded] = useState(false);
  const { consolidatedPortalPrinterCarousel, consolidatedPortalGetAppButton } = useFlags();
  const { stack, shell: { authProvider, store } } = useRootContext();
  const { t } = useI18n();

  const userInfosCall = useUsersApiCall({
    authProvider,
    stack,
    store,
  });

  const { data: userInfos } = userInfosCall;

  const {
    pending: isLoadingDevices,
    data: devices,
  } = useDeviceCacheApiCall({
    authProvider,
    stack,
    store,
  });

  const accountHasDevice = useMemo(() => devices && devices.length > 0, [devices]);
  const showNotificationsAndCarousel = useMemo(
    () => !isLoadingDevices && accountHasDevice,
    [accountHasDevice, isLoadingDevices],
  );

  const urlHPApp = useMemo(() => {
    const { userOSDetector } = userOSHelper;
    const os = userOSDetector() || '';
    let url = '';

    if (os.match(/Windows/i)) {
      url = APP_URLS.WINDOWS_APP_URL;
    } else if (os.match(/Mac/i)) {
      url = APP_URLS.MAC_APP_URL;
    } else if (os.match(/iOS/i)) {
      url = APP_URLS.IOS_APP_URL;
    } else if (os.match(/Android/i)) {
      url = APP_URLS.ANDROID_APP_URL;
    }

    return url;
  }, []);

  const openOSStoreForHpApp = () => {
    if (urlHPApp) {
      window.open(urlHPApp, '_self');
    }
  };

  return (
    <Container data-testid={dataTestId} accountHasDevice={isLoadingDevices || accountHasDevice}>
      <TitleAndNotificationsWrapper accountHasDevice={accountHasDevice}>
        {showNotificationsAndCarousel && (
          <NotificationsWidget
            isExpanded={noticationsIsExpanded}
            setIsExpanded={setNotificationsIsExpanded}
            stack={stack}
          />
        )}
        {!noticationsIsExpanded
        && (
        <Title data-testid="user-name" className="title-regular">
          {t('dashboard.header.welcome.option1', {
            name: userInfos?.data?.givenName,
            interpolation: { escapeValue: false },
          })}
        </Title>
        )}
      </TitleAndNotificationsWrapper>

      {showNotificationsAndCarousel && consolidatedPortalPrinterCarousel && (
        <PrinterCarouselContainer data-testid="printer-carousel-container">
          <PrinterCarousel devices={devices} stack={stack} />
        </PrinterCarouselContainer>
      )}

      {!isLoadingDevices && !accountHasDevice && (
        <MessageContainer>
          <p data-testid="connect-printer-message" className="subtitle-large">{t('dashboard.header.connectPrinter')}</p>
          {consolidatedPortalGetAppButton && urlHPApp
            && (
            <Button
              data-testid="get-the-app-button"
              appearance="primary"
              onClick={openOSStoreForHpApp}
            >
              {t('dashboard.header.getTheApp')}
            </Button>
            )}
        </MessageContainer>
      )}
    </Container>
  );
};

Banner.defaultProps = {
  'data-testid': null,
};

Banner.propTypes = {
  'data-testid': PropTypes.string,
};

export default Banner;
