import React, { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import GenericThemeProvider from '../../shared/GenericThemeProvider';
import {
  Container, Grid, PrintSecurityContainer, RemotePrintContainer, StatusContainer, UsageContainer,
} from './styles';
import SolutionCards from './SolutionCards';
import HPUsageDataWidget from '../HPUsageDataWidget';
import useAccountApiCall from '../../hooks/useAccountApiCall';
import StatusWidget from '../StatusWidget';
import Banner from './Banner';

const ConsolidatedDashboard = ({ stack, shell }) => {
  const { dashboardUsageDataUnitFromOrg } = useFlags();

  const { localization: { country: urlCountry } } = shell;

  const currentDate = useMemo(() => new Date(Date.now()), []);

  const accountCall = useAccountApiCall({
    init: dashboardUsageDataUnitFromOrg,
  });

  const country = dashboardUsageDataUnitFromOrg ? (accountCall.data?.regionId || urlCountry) : urlCountry;

  return (
    <GenericThemeProvider>
      <Container data-testid="consolidated-dashboard">
        <Banner data-testid="banner-container" />

        <Grid>
          <SolutionCards />

          <StatusContainer data-testid="status-container">
            <StatusWidget stack={stack} />
          </StatusContainer>

          <UsageContainer data-testid="hp-usage-data-container">
            <HPUsageDataWidget
              data-testid="addons-hp-data-usage-widget"
              currentDate={currentDate}
              country={country}
            />
          </UsageContainer>

          <PrintSecurityContainer
            data-testid="printer-security-container" background="filled"
            border="outlined"
          />

          <RemotePrintContainer
            data-testid="remote-print-container" background="filled"
            border="outlined"
          />
        </Grid>
      </Container>
    </GenericThemeProvider>
  );
};

ConsolidatedDashboard.defaultProps = {
  stack: 1,
};

ConsolidatedDashboard.propTypes = {
  stack: PropTypes.number,
  shell: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ConsolidatedDashboard;
