import React from 'react';
import PropTypes from 'prop-types';
import {
  CardBody, CardContainer, ContentContainer, Description, Footer, HeaderContainer, HeaderText,
  IconContainer, Title,
  TitleContainer,
} from './styles';

const SolutionCardTemplate = props => {
  const {
    headerText,
    title,
    description,
    icon,
    footerText,
    footerHyperlink,
    'data-testid': dataTestId,
  } = props;

  return (
    <CardBody
      data-testid={dataTestId}
      border="dropShadow"
      content={(
        <CardContainer>
          <HeaderContainer>
            <HeaderText className="caption-regular">{headerText}</HeaderText>
          </HeaderContainer>
          <ContentContainer>
            <TitleContainer>
              <Title className="subtitle-large">{title}</Title>
              <IconContainer>
                {icon}
              </IconContainer>
            </TitleContainer>
            <Description className="caption-regular">{description}</Description>
            <Footer>
              <a className="label-regular" href={footerHyperlink}>{footerText}</a>
            </Footer>
          </ContentContainer>
        </CardContainer>
      )}
    />
  );
};

SolutionCardTemplate.propTypes = {
  headerText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  footerHyperlink: PropTypes.string.isRequired,
  footerText: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  'data-testid': PropTypes.string.isRequired,
};

export default SolutionCardTemplate;
