import React, { memo, useMemo } from 'react';
import { MFELoader } from '@jarvis/react-portal-addons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import useTheme from '@veneer/theme/dist/use_theme';
import primitives from '@veneer/primitives';
import PropTypes from 'prop-types';
import {
  getModuleDisplayedEvent,
  getModuleInitializedEvent,
  publishEvent,
} from '../../utils/analytics';

const MFE_REFS = {
  STATUS: '@jarvis/react-ecp-status-widget',
};

const StatusWidget = ({ stack }) => {
  const theme = useTheme();

  const { consolidatedPortal } = useFlags();

  const roleFilterStatusWidget = useMemo(() => [
    'User-BusinessTransactionalSMB',
    'Admin-BusinessTransactionalSMB',
    'Invitation',
    ...(consolidatedPortal ? [
      'Owner-Personal',
      'User-Personal',
      'Personal',
    ] : []),
  ], [consolidatedPortal]);

  return (
    <MFELoader
      analyticsEvents={{
        moduleDisplayed: getModuleDisplayedEvent('/ReactEcpStatusWidget/', 'Status'),
      }}
      card
      component={MFE_REFS.STATUS}
      data-testid="status-widget-mfe"
      header={false}
      onLoad={() => {
        publishEvent(getModuleInitializedEvent('ReactEcpStatusWidget'));
      }}
      roleFilter={roleFilterStatusWidget}
      stack={stack}
      stateFilter={['Active', 'Pending', 'Expired']}
      settings={{
        enableLocalization: true,
        showUsers: true,
        contentStyle: `
        height: 100%;
        max-height: 100%;
        margin-top: 0;
      `,
        bodyStyle: `
        padding-bottom: 8px;
      `,
        itemHeight: '49px',
        itemsMargin: '0',
        itemsPadding: '0',
        mainItemHeight: '49px',
        itemMargin: '0',
        itemPadding: '0 0 0 12px',
        titlePadding: '0 0 0 8px',
        chevronSize: '17px',
        circleRadius: '50%',
        circleSize: '12px',
        titleGap: '0',
        subTitleGap: '12px',
        onlineColor: theme.color.primary.base.default,
        offlineColor: theme.color.white,
        activeColor: theme.color.positive.base.default,
        pendingColor: theme.color.foreground.light,
        expiredColor: theme.color.negative.base.default,
        offlineBorderColor: theme.color.neutral.base.default,
        devicesRedirectPath: '/printers',
        usersRedirectPath: '/users',
        cardBorderRadius: `${primitives.layout.cornerRadius4}px`,
        singleLoader: true,
        listDevicesHeight: '154px',
        listUsersHeight: '196px',
      }}
      customTexts={{
        'status.header.devices.total': 'status.header.devices.totalPrinters',
        'status.header.devices.offline': 'status.header.devices.notConnected',
        'status.header.devices.online': 'status.header.devices.connected',
      }}
    />
  );
};

StatusWidget.defaultProps = {
  stack: 1,
};

StatusWidget.propTypes = {
  stack: PropTypes.number,
};

export default memo(StatusWidget);
